.shopProducts{
    width:100%;
}
.shopHeader{
    position: relative;
}
.shopProductsWrapper{
    border-bottom: 1px solid lightgray;
}
.shopHeaderImg{
    width:100%;
    height:400px;
}
.shopHeaderDetail{
    max-width:800px;
    padding:20px;
    text-align: center;
    position:absolute;
    top:60%;
    left:50%;
    transform: translate(-50%,-50%);
    background:#fff;
}
.shopHeaderTitle{
    font-size:28px;
    font-weight: 700;
    margin:10px 0;
}
.shopHeaderPara{
    font-size:17px;
    margin:20px 0;
}
.shopProductsWrapper{
    width:80%;
    margin:0 auto;
    padding:60px 0;
}
.shopAllProducts{
    width:100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.shopTabsContainer{
    display: flex;
    justify-content: center;
    margin:20px 0;
    flex-wrap: wrap;
}
.shopTabItem{
    margin:0 20px;  
    /* border:1px solid lightgrey; */
    padding:10px 15px;
    font-weight: 600;
    font-size:18px;
}
.tabActive{
    background:lightgrey;
}
.shopTabItem:hover{
    background:lightgrey;
}
.shopFilter{
    text-align: right;
    margin:40px;
}
.shopFilter span{
    font-weight: 700;
    font-size:18px;
}
.shopFilterInput{
    background:#f3f3f3;
    padding:10px;
    width:250px;
}

.shopFilterInput:active{
    outline: none;
    border: none;
}
.shopFilterInput:visited{
    outline: none;
    border: none;
}
.shopProductOne{
    width:350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding:20px 0;
}
/* .productRating{
    display: flex;
    align-items: center;
} */
.productRating .MuiSvgIcon-root {
    font-size:22px;
    color: rgb(240, 240, 64);
}
.productRating span{
    font-size:14px;
    margin-left:10px;
}

.s_ProductHead{
    font-size:22px;
    font-weight: 700;
}
.s_productCategory{
    margin:5px 0;
}
.s_ProductQty{
    font-size:15px;
    margin-top:20px;
}
.s_ProductQty span{
    margin:0 4px;  
}
.s_ProductPrice{
    margin:15px 0;
    font-size:24px;
    font-weight: 700;
}
.s_ProductBtn{
    padding:12px 30px;
    background:#09d796;
    background-color: #b0d13f;
    color:#fff;
    font-weight: 700;
    box-shadow: 5px 5px 0px 0px #09d796;
}

@media (max-width:930px){
    .shopHeaderDetail{
        width:90%;
    }
}
@media (max-width:650px){
    .shopHeaderImg{
        width:100%;
        height:600px;
        object-fit: cover;
    }
    .shopFilter{
        margin:0px;
    }
}
@media (max-width:400px){
    .shopFilterInput{
        background:#f3f3f3;
        padding:10px;
        width:180px;
    }
    
}