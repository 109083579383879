.product{
    width: 100%;
    height:100%;
    background: #ffffff;
    padding:30px 0;
    
}
.product__header{
    width:100%;
    /* height: 35vh; */
    /* position: relative;
    display: flex;
    justify-content: center;
    align-items: center; */
}
.product__items{
    width:90%;
    margin:0 auto;
    display: flex;
    justify-content: center;
}
.productImgContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.boxItemOne{
    margin:20px 10px;
}
.boxItemOne .lyfeBoxImg{
    width:220px;
    height:220px;
}
.boxItemOne h2{
    text-align: center;
    font-size:20px;
    margin:5px 0;
}
.boxItemOne a{
    text-decoration: none;
    color: #000;
}