.speedDial {
  position: fixed;
  right: 2vmax;
  top: 3vmax;
}

.speedDialIcon {
  width: 56px;
  height: 56px;
  border-radius: 100%;
}
@media (max-width: 768px) {
  .speedDial {
    top: 21vmax;
  }
}
