*{
    box-sizing: border-box;

}
.productAnalysis{
    background: #f7f7f0;
    padding:10px 0;
}
.product{
    width: 100%;
    height:100%;
    padding:30px 0;
}
.product__header{
    width:100%;
    margin-bottom: 60px;
}
.analysisWrapper{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.analysisItems{
    width: 170px;
    background-color: #fff;
    display: block;
    height:100%;
    padding:20px 10px;
    text-align: center;
    margin:80px 20px;
    position: relative;
}

.analysisImg{
    width:140px;
    height:150px;
    border-radius: 50%;
    position: absolute;
    top:-110px;
    left:50%;
    transform: translateX(-50%);
    object-fit: cover;
}
.analysisText{
    margin:30px 0;
}
.analysisHead {
    font-size: 26px;   
    text-align: center; 
    margin:30px 0;
    font-weight: 500;
    
}
.analysisPara{
    font-size:16px;
    line-height: 30px;
    padding:0 20px;
    margin:10px 0;
}
.analysisBtn{
    font-size: 18px;
    line-height: 20px;
    text-transform: capitalize;
    margin-top:40px;
    display: flex;
    justify-content: center;
    text-align: center;
    color:#fff;
    font-weight: 700;
    text-decoration: none;
}
.analysisBtnDiv{
    width:110px;
    padding:20px;
    background:#b0d13f;
}
/* .product__items{
    width:90%;
    margin:0 auto;
    display: flex;
    justify-content: center;
}
.productImgContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.boxItemOne{
    margin:20px 10px;
}
.boxItemOne .lyfeBoxImg{
    width:220px;
    height:220px;
}
.boxItemOne h2{
    text-align: center;
    font-size:20px;
    margin:5px 0;
} */