/* .custom-bg {
  background-color: #383838;
  background-image: url("images/tea-cropped.jpg");
} */
.custom-svgbg {
  background-color: #383838;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23ddddad' fill-opacity='0.6'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.placeholderColor::placeholder {
  color: rgba(0, 86, 47, 0.9);
  opacity: 1;
}
select option:disabled {
  display: none;
}
input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: rgba(0, 86, 47, 0.9);
  width: 1.15em;
  height: 1.15em;
  border: 0.1em solid rgba(0, 86, 47, 0.9);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em rgba(0, 86, 47, 0.9);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

#selectAnalysis{
  width:100%;
  padding:4px;
  outline: none;
  font-size:18px;
}
#ques1,#ques2,#ques3,#ques4,#ques5,#ques6,#ques7{
  display: none;
}
#question2,#question3,#question4,#question5,#question6,#question7{
  display: none;
}