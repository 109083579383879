.homeHeader {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}
.homeNavbar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 0;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.4s;
}
.homeNavbarDark {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  background: rgb(82, 78, 78);
  transition: all 0.4s;
}
.homeNavbar.homeNavColor {
  background: rgba(0, 0, 0, 0.7);
}
.homeHeaderWrapper {
  width: 87%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.homeLogo {
  display: flex;
  align-items: center;
  color: rgb(238, 238, 238);
  width: 30%;
}
.homeLogo .homeLogoImg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}
.homeLogo .logoHead {
  font-size: 22px;
  font-weight: 600;
}
.homeLogo .logoSubhead {
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 1px;
}

.homeNavWrapper {
  width: 70%;
  list-style: none;
  display: flex;
  justify-content: space-around;
}
.homeNavItem {
  position: relative;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}
.homeNavItem a {
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 22px;
}
.homeAnalysis {
  color: #fff;
  font-weight: 700;
  font-size: 22px;
}
.hoverNavInner {
  position: absolute;
  top: 30px;
  left: 0;
  line-height: 45px;
  width: 250px;
  list-style: none;
  background-color: #fff;
  display: none;
}
.homeNavItem:hover .hoverNavInner {
  display: block;
}
.hoverInnerItem a {
  font-size: 16px;
  padding: 4px 10px;
  color: #000;
}
.hoverInnerItem:hover {
  background-color: rgb(240, 240, 240);
}
.homeNav {
  display: none;
}

@media (max-width: 760px) {
  .homeNavWrapper {
    display: none;
  }
  .homeNav {
    display: block;
  }
  .homeNav {
    color: #fff;
  }
  .homeNav .MuiSvgIcon-root {
    font-size: 35px;
    cursor: pointer;
  }

  .homeSidebar {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 100vh;
    background-color: rgb(82, 78, 78);
    transition: 0.5s right;
  }
  .homeSidebarOpen {
    position: absolute;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100vh;
    background-color: rgb(82, 78, 78);
    transition: 0.5s right;
  }
  .homeNavLinn {
    text-decoration: none;
    color: #fff;
  }
  .homeSideClose {
    padding: 30px;
    border-bottom: 1px solid rgb(110, 110, 110);
  }
  .homeSideClose .MuiSvgIcon-root {
    font-size: 50px;
  }
  .homeSideWrapper .homeNavItems {
    list-style: none;
  }
  .homeNavItems .homeNavItem {
    font-size: 20px;
    font-weight: 600;
    padding: 25px;
    border-bottom: 1px solid rgb(110, 110, 110);
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .homeNavItemsInner {
    position: relative;
    list-style: none;
    margin-top: 40px;
    width: 80%;
  }
  .homeNavItemInner {
    margin-bottom: 20px;
  }
}

/* 

@media (max-width:450px){
    
    .homeSidebar{
        right:0;
        width:300px;
    }
    .homeSidebarOpen{
        right:-400px;
        width:300px;
    }
}
@media (max-width:350px){
    .homeNavbar {
        padding: 40px 0;
    }
    .homeNavbarDark{
        padding:30px 0;
    }
    .homeSidebar{
        right:0;
        width:250px;
    }
    .homeSidebarOpen{
        right:-400px;
        width:250px;
    }
    .homeLogo .homeLogoImg {
        width: 40px;
        height: 40px;
    }
    .homeLogo .logoHead{
        font-size:20px;
    }
    .homeLogo .logoSubhead{
        font-size:10px;
    }
} */
