.review{
    width:100%;
    background: #f7f7f0;
    padding:50px 0;
}
.review__wrapper{
    width:80%;
    margin:0 auto;
}
.review__wrapper__head{
    font-size: 40px;
    padding-bottom: 40px;
    text-align: center;
}
.review__features{
    display: flex;
    padding-bottom: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.review__feature{
    width: 400px;
    margin-bottom: 20px;
    padding:20px 0;
    background-color: #fff;
    border-radius: 3px;
    padding:22px;
    
}
.review__feature__inner{
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}
.review__feature__info{
    padding:10px 0;
}
.review__feature__img{
    width:70px;
    height:55px;
    object-fit: contain;
    padding-right: 20px;
}
.review__feature__info h1{
    font-size: 18px;
}
.review__feature__img img{
    width: 100%;
    height:100%;
    border-radius: 50%;
}
.review__feature__info p{
    color: grey;
    font-size: 14px;
}
.review__desc{
    color:grey;
}
@media (max-width:720px) {
    .review{
        padding:40px 0px;
    }
}