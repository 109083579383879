* {
    min-height: 0;
    min-width: 0;
  }
.reason-being{
    width:100%;
    background: #fff;
    padding:50px 0;
}
.reasonContainer{
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.reasonSlider{
    width:70%;
    background:#f3f3f3;
    padding:30px;   
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: left;
}
.reasonDetail{ 
    margin-left:15px;
    width:50%;
}
.reasonDetail .reasonDetailHead{
    font-size:40px;
    margin: 30px 0;
}
.reasonDetailPara{
    font-size:17px;
    padding-right:10px;
    margin:5px 0;
}
.reasonImgContainer{
    width:400px;
    height:300px;
}
.reasonImg{
    width:100%;
    height:100%;
    object-fit: cover;
}
.reason-being button{
    background-color: #fff !important;
}
.reason-being .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
    width:20px;
    height:20px;
    background:#000;
    -webkit-clip-path: polygon(100% 0, 0 53%, 100% 100%);
    clip-path: polygon(100% 0, 0 53%, 100% 100%);
}
.reason-being .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000;
    width:20px;
    height:20px;
    background:#000;
    -webkit-clip-path: polygon(0 0, 0% 100%, 100% 52%);
    clip-path: polygon(0 0, 0% 100%, 100% 52%);
}

@media (max-width:1250px){
    .reasonDetail{ 
        margin-left:15px;
        width:400px;
    }
    .reasonImgContainer{
        width:300px;
        height:300px;
    }
}
@media (max-width:1150px){
    .reasonDetail{ 
        margin-left:15px;
        width:80%;
        margin-bottom:20px;
    }
    .reasonImgContainer{
        width:80%;
        height:300px;
    }
    .reasonSlider{
        width:60%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
        padding:40px 0;
    }
}

@media (max-width:620px){
    .reasonSlider{
        width:80%;
    }
}