.tea-details-container {
  margin-top: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tea-details-heading {
  text-align: center;

  font-size: 2rem;
  font-weight: 600;
  color: #2e524a;
}
.product-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding: 10px;
}
.prod-img {
  width: 40%;
  padding: 10px;
}
.tea-details-para {
  width: 60%;

  text-align: center;
  font-size: 1.3rem;
  font-weight: 400;
  color: #3f6e64;
}
@media (max-width: 768px) {
  .tea-details-para {
    width: 95%;
  }
  .product-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.curved {
  position: relative;
  background: #2c3e50;
  height: 60vh;
}

.curved::after {
  content: "";
  border-top-left-radius: 50% 100%;
  border-top-right-radius: 50% 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #fff;
  height: 15%;
}
.steps-container {
  background-color: #2e524a;
  width: 100%;
  padding: 3.75rem 1rem;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 1.3rem;
}
.sub-heading {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}
.steps-sub {
  width: 80%;
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  border-radius: 0.7rem;
}
.step-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tea-text-white {
  color: #fff;
}
@media (max-width: 768px) {
  .steps-sub {
    width: 95%;
    grid-template-columns: repeat(1, 1fr);
  }
  .curved {
    position: relative;
    background: #2c3e50;
    height: 90vh;
  }
  .curved::after {
    content: "";
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    height: 5%;
  }
}
