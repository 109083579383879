.qualityaccom{
    width: 100%;
}

.qualityaccom__backimg{
    width:100%;
    height: 550px; 
    position: relative; 
    
}
.qualityaccom__backimg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(80%);
}
.qualityaccom__inner__content{
    position: absolute;
    z-index:999;
    bottom:150px;
    left:150px;
}
.qualityaccom__desc{
    font-size: 50px;
    color: #fff;
    width: 100%;
    font-weight: 700;
}
.qualityaccom__para{
    font-size:18px;
    color:#fff;
    width:70%;
    margin:20px 0;
}
.qualityaccom__subhead{
    font-size:18px;
    color:#fff;
    width:70%;
    margin:20px 0;
    border-left:5px solid #ffc107;
    padding-left:10px;
    font-size:16px;
}
.qualityaccom__btn{
    color:#fff;
    width:250px;
    margin-top: 30px;
    background-color: #ffc107;
    padding:18px 40px;
    cursor: pointer;
    font-size:15px;
    border:1px solid #ffc107;
    margin-right:5px;
}
@media (max-width:760px) {
    .qualityaccom__desc{
        font-size: 32px;
    }
    .qualityaccom__backimg{
        height: 500px;
    }
    .food {
        padding: 40px 20px;
    }
    .qualityaccom__btn {
        padding: 16px 39px;
        cursor: pointer;
        z-index: 999;
    }
    .qualityaccom__inner__content{
        position: absolute;
        z-index:999;
        bottom:100px;
        left:100px;
    }
}

@media (max-width:610px){
    .qualityaccom__inner__content{
        position: absolute;
        z-index:999;
        bottom:100px;
        left:50px;
    }
    .qualityaccom__para {
        font-size: 18px;
        color: #fff;
        width: 90%;
        margin: 20px 0;
    }
    .qualityaccom__btn{
        display: block;
        margin-top: 5px;
    }
    
}
@media (max-width:480px){
    .qualityaccom__backimg{
        height: 600px;
    }
    .qualityaccom__inner__content{
        position: absolute;
        z-index:999;
        bottom:140px;
        left:20px;
    }
    .qualityaccom__desc {
        font-size: 30px;
    }
    .qualityaccom__para {
        font-size: 16px;
    }
}