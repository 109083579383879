.sweet-warning {
    background-color: #505255;
}

.sweet-warning:not([disabled]):hover {
    background-color: #ffc222;
}

.main-img {
    width: 100%;
    height: 80vh;
}
.contactWrapper{
    border-bottom: 1px solid lightgrey;
}
.setFocus:hover,
.setFocus:focus,
.setFocus:active {
    background: linear-gradient(#000000, #000000);
    background-color: #000000 !important;
    /* for IE */
    color: #ffed00 !important;
}
.grid-bg{
    background: transparent;
}
