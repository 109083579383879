*{
    margin:0;
    padding:0;
    box-sizing: border-box;
}
.nav__header{
    width:100%;
    background:rgba(0,0,0,0.5);
}
.product__nav{
    width:90%;
    margin:0 auto;
    height:100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size:25px;
    padding:0 10px;

}
.cartIcon{
    position: relative;
    cursor: pointer;
    color:#ffffff;
}
.cartIcon .MuiSvgIcon-root {
    font-size:30px;
}
.cartNumber{
    position: absolute;
    top:-4px;
    right:-8px;
    background:#ffffff;
    border-radius: 50%;
    padding:1px;
    color: #000;
    font-weight: 700;
    text-align: center;
    font-size:16px;
    width:15px;
    height:22px;
}
.sideBarCart{
    position: fixed;
    background:rgb(243, 243, 243);
    box-shadow: 10px 0px 30px 1px #000;
    top:0;
    right:0px;
    width:400px;
    height:100vh;
    z-index:999;
    transition: 0.5s right;
}
.sideBarCartHide{
    position: fixed;
    background:rgb(243, 243, 243);
    box-shadow: 10px 0px 30px 1px #000;
    top:0;
    right:-420px;
    width:400px;
    height:100vh;
    z-index:999;
    transition: 0.5s right;
}
.sideCart{
    position: absolute;
}
.crossIcon{
    text-align: right;
    margin:20px;
    font-size:22px;
    cursor: pointer;
}
.sideCardHead{
    font-size:22px;
    margin:30px;
}
.sideCartProduct{
    display: flex;
    margin:20px;
}
.sideCartProduct .sideProductImg{
    width:90px;
    height:90px;
}
.sideProductDetail {
    margin:10px;
    text-align: left;
}
.sideProductName{
    font-size:15px;
}
.sideProductQp span{
    font-size:16px;

}
.sideProductRemove{
    font-size:20px;
    margin:5px;
    cursor: pointer;
}
.sideCartTotal{
    display: flex;
    justify-content: space-between;
    margin:0 20px;
}
.sideCartTotal span{
    font-size:18px;
    font-weight: 400;
}
.sideCartBtn{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin:30px 20px;
}
.sideCartBtn .sideViewCartBtn{
    width:100%;
    text-decoration: none;
    color:#fff;
    background-color: rgba(0,0,0,0.8);;
    margin:5px 0px;
    padding:15px;
    text-align: center;
    font-size:18px;
}
@media (max-width:450px){
    .sideBarCart{
        right:0px;
        width:330px;
    }
    .sideBarCartHide{
        right:-420px;
        width:330px;
    }

    .sideProductDetail {
        margin:5px;
    }
    .sideProductName {
        font-size: 14px;
    }
}
@media (max-width:320px){
    .sideBarCart{
        width:280px;
    }
    .sideBarCartHide{
        width:280px;
    }
}